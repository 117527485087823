
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        
















.author__image {
  @include fluid(
    width,
    (
      mob: 120px,
      xl: 140px,
    )
  );
  @include fluid(
    height,
    (
      mob: 120px,
      xl: 140px,
    )
  );

  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;

  img {
    @include image-fit;
  }
}

.author__name {
  @include manier;
  @include fluid(
    font-size,
    (
      mob: 17px,
      xl: 24px,
    )
  );

  font-weight: 700;
  line-height: 1.2;
}
