
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        






































































.news.razzmatazz::v-deep {
  .hero {
    background: $razzmatazz;
  }

  .news__content {
    h1,
    h2,
    h3,
    h4,
    blockquote p,
    blockquote footer span {
      color: $razzmatazz;
    }
  }
}

.news.cognac::v-deep {
  .news__content {
    h1,
    h2,
    h3,
    h4,
    blockquote p,
    blockquote footer span {
      color: $cognac;
    }
  }
}

.news__content {
  @include mq(xl) {
    width: inside-col(11, 20);
  }

  @include mq(xxxl) {
    width: inside-col(11);
  }
}

.news__sidebar {
  text-align: center;
  border-top: 1px solid $alto;
  border-bottom: 1px solid $alto;

  @include mq(s) {
    width: inside-col(3, 20);
    margin-right: inside-col(2, 20);
    text-align: left;
    border: 0;
  }

  @include mq(xxl) {
    width: inside-col(3);
    margin-right: inside-col(2);
  }
}

.news__share {
  .category {
    font-size: 1.1rem;
  }
}

.news__end {
  display: none;
}

@include mq(s) {
  .news__body {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .news__tags {
    margin: 25px 0;
  }

  .news__end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid $alto;

    .category {
      font-size: 11px;
    }

    .share {
      margin-left: 15px;
    }
  }
}
