
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        
























.share ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.share li {
  display: inline-block;
}

.share a {
  display: inline-block;
}

.share svg {
  fill: $black;
}
